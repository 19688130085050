 /* Section types */
import { IGatsbyImageData } from 'gatsby-plugin-image'

export type SectionTheme = 'gray' | 'primary' | 'secondary' | 'white' | 'green'
export type SanityButtonTheme = "blue" | "white" | "outline" | "orange" | "green" | "dark";
export type SectionSize = 'small' | 'medium' | 'default'
export type SectionPaddingSize = SectionSize | 'none'
export type CTA = { buttonLink: string; buttonText: string }
export type Logo = {
  title: string
  url: string
  image: {
    asset: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  imageLarge: {
    asset: {
      gatsbyImageData: IGatsbyImageData
    }
  }
}

export type ICard = {
  title: string
  paragraph: {
    paragraph: string
  }
  image: {
    asset: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  alt: {
    alt: string
  }
}

export type Image = {
  asset: {
    gatsbyImageData: IGatsbyImageData
    id: string
    width: number
    height: number
    title?: string
    description?: string
    altText?: string
    originalFilename: string
  }
  link?: string
}

export type ImageSimple = {
  asset: {
    gatsbyImageData: IGatsbyImageData
  }
  title: string
}

export type RowReference = {
  id
  title: string
  subtitle?: string
  accentTitle: boolean
  _rawParagraph: any[]
  buttonLink: string
  buttonText: string
  quickPoints?: string[]
  images?: Image[]
  isTextCenter: boolean
  isDropdown?: boolean
  dropdownImage?: {
    asset: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  dropdownMobileImage?: {
    asset: {
      gatsbyImageData: IGatsbyImageData
    }
  }
}

export type ListItem = {
  title?: string
  _type: string
  id: string
  isOrderedList?: boolean
  listItems: {
    id
    _rawParagraph: any[]
  }[]
}
export type ParagraphSpecialSection = {
  _type: string
  id: string
  _rawParagraph: any[]
}
export type TableSpecial = {
  _type: string
  id: string
  rows: {
    id: string
    isTableHeader: boolean
    _rawFirstColumnContent: any[]
    _rawSecondColumnContent: any[]
  }[]
}
export type SpecialContent =
  | (ListItem | ParagraphSpecialSection | TableSpecial)[]
  | null

export enum ARTICLE_TYPE {
  ARTICLE = 'article',
  NEWS_RELEASE = 'newsRelease'
}

export const ARTICLE_TAXONOMY = {
  [ARTICLE_TYPE.ARTICLE]: '/blog/',
  [ARTICLE_TYPE.NEWS_RELEASE]: '/newsroom/'
}

export interface ArticleTopic {
  id: string
  title: string,
  slug: string,
  image?: Image,
  alt?: string
  thumbnailImage: Image
  thumbnailAlt: string
  description?: string,
}

export type Article = {
  _type: ARTICLE_TYPE
  id: string
  title: string
  seoDescription: string
  publishedAt: string
  _rawArticleContent: any[]
  mainImage: {
    asset: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  mainImageAltText?: string
  slug: string
  author?: {
    name: string
    positions: string[]
  }
  topics: ArticleTopic[]
}

export type ProductPage = {
  handle: string
  subtitle: string
  slug: string
  seoTitle: string
  seoDescription: string
  seoImage: {
    asset: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  seoPathname: string
  seoRobots: string
  shortDescription: string
  filterSku: string
  excludedFromCollection: boolean
  affirmEnabled: boolean
  extendEnabled: boolean
  yotpoEnabled: boolean
  rebatesEnabled: boolean
  sectionReference: any[]
}

export type VariantSelectorType = 'buttons' | 'input' | 'tiered';

export type ProductCopy = {
  handle: string
  subtitle: string
  slug: string
  seoPathname: string
  shortDescription: string
  descriptionAsDropdown?: boolean
  filterSku: string
  excludedFromCollection: boolean
  affirmEnabled: boolean
  extendEnabled: boolean
  yotpoEnabled: boolean
  rebatesEnabled: boolean
  soldOutText?: string
  availabilityWarningText?: string
  amazonUrl?: string
  amazonUrlText?: string
  topBannerText?: string
  backInStockEnabled: boolean
  klaviyoBackInStockListId?: string
  passwordProtectedEnabled: boolean
  variantSelector?: VariantSelectorType
  tieredDiscountCode?: string
  hideVariantPrices?: boolean
  variantSelectorTitle: string
  variantSelectorDescription?: string
  badgeColor?: {
    hex: string
  }
  badgeEnabled: boolean
  badgeSaleText?: string
  badgeText: string
  badgeDisplayDiscount?: boolean
  bundleUpsellProduct?: BundleCopyProduct
  bundleUpsellCtaText?: string
  bundleUpsellCtaButtonText?: string
  bundleUpsellNavigateToBundlePage?: boolean
  quantitySelectionEnabled?: boolean
  quantitySelectionUpsellText?: string
  upsellModalEnabled?: boolean
  upsellModalProduct?: ProductCopy
  upsellModalBannerText?: string
  productUpsellListEnabled?: boolean
  productUpsellListTitle?: string
  productUpsellList?: SanityProductUpsell[] 
  productRating?: number
  displayShopifyDescriptionHTML?: boolean
  freeGiftEnabled?: boolean
  freeGift: {
    id: string
    description: string
    title?: string
    discountCode: string,
    accessibilityTitle?: string,
    giftVariantSku?: string,
    product: {
      handle: string
      shortDescription: string
      subtitle: string
      seoPathname: string
    }
  }
}

export type BundlePageCopy = {
  id: string
  slug: string
  title: string
  seoTitle: string
  seoPathname: string
  description: string
  subtitle: string
  shortDescription: string
  images: ImageSimple[]
  excludedFromCollection: boolean
  dealId: string
  bundleType: "combos" | "bundles"
}

export const SANITY_MOBILE_RESPONSIVE_IMAGE_BREAKPOINT = 600;

export type TextAlign = 'left' | 'right' | 'center';

export type SanitySEO = {
  seoTitle: string
  seoRobots: string
  seoDescription: string
  seoPathname: string
  seoImage?: {
    asset: {
      url: string
    }
  }
}

export enum VIDEO_TYPE {
  IFRAME = 'iframe',
  HOSTED = 'hosted'
}

export type SanityVideo = {
  title: string
  videoType: VIDEO_TYPE
  enableLoop?: boolean
  videoId: string
  videoMp4Src: string
  videoWebmSrc?: string
  enableControls?: boolean
  posterImage: {
    asset: {
      url: string
    }
  }
}

export type SanityProductUpsell = {
  id: string
  description?: string
  active: boolean
  title?: string
  accessibilityTitle?: string,
  purposeText: string,
  product: {
    handle: string
    shortDescription: string
    subtitle: string
    seoPathname: string
  }
}

export enum BUNDLE_LAYOUTS {
  SINGLE = "single",
  LIST = "list"
}

export interface BundleCopyProduct {
  dealId: string,
  title: string,
  subtitle: string,
  bundleTitle: string,
  shortDescription: string,
  bundleType: "combos" | "bundles" | "builders"
  seoDescription: string,
  seoTitle: string,
  seoPathname: string,
  _rawDescription: any[],
  layout: BUNDLE_LAYOUTS,
  enableQuantitySelectionForBogoAddonProducts?: boolean
  enableQuantitySelectionForBundleBuilders?: boolean
  images: {
    asset: {
      gatsbyImageData: IGatsbyImageData,
    },
    id: string
    title: string
  }[],
  carouselImages: {
    asset: {
      gatsbyImageData: IGatsbyImageData,
    },
    id: string
    title: string
  }[]
}

export type Discount = {
  amount: number | null
  code: string
  isActive?: boolean
  percentage?: number | null
  products?: {
    sku: string
  }[]
  summary: string
  minimumAmountOfProducts?: number
}

export type CustomerStoryLayout = 'grid' | 'slider';

export type CustomerStory = {
  _id: string,
  name: string
  featured?: boolean,
  summary?: string
  tagline?: string
  headshotImageAltText?: string
  headshot?: {
    asset: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  image?: {
    asset: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  imageThumbnail?: {
    asset: {
      gatsbyImageData: IGatsbyImageData
    }
  }
  imageAltText?: string
  products: {
    shortDescription: string,
    subtitle: string,
    handle: string,
    seoPathname: string
  }[]
  video?: {
    title: string
    videoMp4Src: string,
    videoWebmSrc?: string,
    enableControls?: boolean
    posterImage: {
      asset: {
        url: string
      }
    }
    posterImageThumbnail: {
      asset: {
        gatsbyImageData: IGatsbyImageData
      }
    }
  },
  _rawStory: any[]
}

export type Spec = {
  title: string
  description?: string
  descriptionList?: string[]
}

export type RowDropdownImage = {
  image: {
    gatsbyImageData: IGatsbyImageData
  },
  mobileImage?: {
    gatsbyImageData: IGatsbyImageData
  }
}