import { getGAClientId, getKlaviyoId, getRachioAccountEmail, getRachioAccountId } from "analytics"
import { CheckoutRequiredTrackingProps } from "../types";
import { Attribute, AttributeInput } from "lib/shopify/storefront-api-client/types/storefront.types";

export const checkoutCustomAttributes = ({
  checkoutId,
  gaClientId = getGAClientId(),
  rachioAccountEmail = getRachioAccountEmail(),
  klaviyoId = getKlaviyoId(),
  rachioAccountId = getRachioAccountId()
}: CheckoutRequiredTrackingProps,
  newAttributes?: Attribute[]
): AttributeInput[] => {
  let attrs = [
    {
      key: 'ga_client_id',
      value: gaClientId,
    },
    {
      key: 'checkout_id',
      value: checkoutId,
    },
  ] as Attribute[]

  if (rachioAccountEmail) {
    attrs.push({
      key: 'rachio_account_email',
      value: rachioAccountEmail
    })
  }

  if (klaviyoId) {
    attrs.push({
      key: 'klaviyo_id',
      value: klaviyoId
    })
  }

  if (rachioAccountId) {
    attrs.push({
      key: 'rachio_account_id',
      value: rachioAccountId
    })
  }

  if (newAttributes) {
    attrs = [...attrs, ...newAttributes];
  }

  return attrs.filter(({ value }) => value)
}