import { FragmentHelpers } from "../../types";
import { VARIANT_FRAGMENT } from "./variant-fragment";

export const PRODUCT_FRAGMENT: FragmentHelpers = {
  fragment: `
    ${VARIANT_FRAGMENT.fragment}
    
    fragment ProductFragment on Product {
      id
      availableForSale
      createdAt
      updatedAt
      descriptionHtml
      description
      handle
      productType
      title
      vendor
      publishedAt
      onlineStoreUrl
      tags
      options {
        name
        values
      }
      images(first: 250) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            id
            src: url
            altText
            width
            height
          }
        }
      }
      variants(first: 250) {
        pageInfo {
          hasNextPage
          hasPreviousPage
        }
        edges {
          cursor
          node {
            ...VariantFragment
          }
        }
      }
    }
  `,
  ref: '...ProductFragment'
}